(function jsWorkDesign($) {

    $(document).ready(function() {
        /*******IMPLEMENTAR BUSCARDOR EN ESTA FUNCION ******/
        $('.form-search input').keyup(function() {
            if (!$('.cont-result-search').is(":visible")) {
                $('.cont-result-search').slideToggle('slow');
            }
        });

        /*******CERRAR EL BUSCADOR ******/
        $('.cont-result-search .close-btn').on('click', function() {
            if ($('.cont-result-search').is(":visible"))
                $('.cont-result-search').slideToggle('slow');
        });

        /*******OPEN MENU */
        $('.menu-trigger a').on('click', function(e) {
            e.preventDefault();
            if ($('.cont-menu').is(":visible"))
                $('.cont-menu').fadeOut();
            else
                $('.cont-menu').fadeIn();
        });

        /*****CHANGE FAQS******/
        $('button[id^="btn-"]').on('click', function(e) {
            e.preventDefault();
            //console.log($(this).attr());
            var $id = $(this).data('id');

            $('.cont-faqs div[class^="panel-"]').fadeOut('fast', function() {
                $('.panel-' + $id).fadeIn();
            });
        });

        /******SLIDER CATEGORIA **********/
        if ($('.slider-cat').length > 0) {
            $('.slider-cat').bxSlider({
                pager: true
            });
        }

        /****CHANGE PASSWORDS */
        $('.show-password').on('click', function() {
            var $item = $(this);
            var cambio_test = $(this).closest("div");
            var cambio = cambio_test.find(".password-input");

            if ($(cambio).attr('type') === "password") {
                $(cambio).attr('type', 'text');
                $item.removeClass('hidden-password');
            } else {
                $(cambio).attr('type', 'password');
                $item.addClass('hidden-password');
            }
        });
        /********SHOW HELP ******/
        $('.btn-help, .close-help').on('click', function() {
            if ($('.container-help').hasClass('active'))
                $('.container-help').removeClass('active');
            else
                $('.container-help').addClass('active');
        });

        /*********ADD ANSWER *****/
        $('.btn-answer').on('click', function() {
            var $input_html = '<div class="col-xs-12 m-tb-2"><input class="form-control input-lg" type="text" placeholder="Nombre de la sociedad"></div>';

            $('form.form-question').append($input_html);
        });

        /*********ADD ANSWER *****/


        $('.more-actions>a').on('click', function(event) {
            event.preventDefault();
            event.stopPropagation();

            var $parent = $(this).parent();
            var $panel = $($parent).find('.panel');



            if ($panel.is(':visible'))
                $panel.fadeOut('slow');
            else
                $panel.fadeIn('slow');

        });

    });




})(jQuery);

//alert(special); // would be undefined
